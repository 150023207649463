<template>
<div class="d-flex align-center justify-center">
  <v-carousel
  v-if="gallery"
    :show-arrows="false"
    height="270"
    hide-delimiter-background
    show-delimiter>
    <v-carousel-item id="carousel-img">
      <div class="product-detail-card-tab img-container">
        <img :src= "images" class="carousel-img">
      </div>
     </v-carousel-item>
      <v-carousel-item v-for="(image, i) in gallery" :key="i">
      <div class="product-detail-card-tab img-container">
        <img :src= "image" class="carousel-img">
      </div>
     </v-carousel-item>
  </v-carousel>
  <div v-else class="product-detail-card-tab img-container">
    <img :src= "images" class="carousel-img">
  </div>
</div>
</template>
<script>
export default {
  props: ['gallery', 'images'],
  created () {
    const url = this.$props.images
  }
}
</script>