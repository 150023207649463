<template>
    <div>
       <v-btn
        type="button"
        block
        class="button py-6 onPrimaryActions--text primaryActions">
          <div v-if="product.onhand != 0">
         <v-icon class="onPrimaryActions--text" size="18">mdi-plus</v-icon><span class="onPrimaryActions--text">سبد خرید</span>
         </div>
         <div v-if="product.onhand == 0">
            <span class="onPrimaryActions--text">ناموجود</span> 
         </div>
        </v-btn>
    </div>
</template>
<script>
export default {
    props: ['product']
}
</script>