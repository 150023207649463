<template>
  <div class="d-flex flex-column justify-center align-center">
 <div class="grid my-2">
   <div class="d-flex justify-space-between align-center">
     <div class="d-flex align-center">
       <span class="font">محصولات مرتبط</span>
    </div>     
  </div>
</div>
    <splide :options= "options" :slides = 'slides'>
      <splide-slide v-for="slide  in slides" :key="slide.id">
        <product :slider = 'slide'/>
    </splide-slide>
    </splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import product from '../../../home/components/cards/product'
import { mapState } from 'vuex'
export default {
    components: {
    Splide,
    SplideSlide,
    product
  },
  props: ['options'],
  computed: {
    ...mapState({
      slides: state => state.basket.similarProducts
    })
  }
}
</script>
<style lang="scss" scoped>
.grid{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 95%;
  align-items: center;
}
</style>