<template>
    <v-card class="product-detail-card mb-2" color="surface">
      <v-row class="mr-2">
       <div class="title-container">
         <div><span class="onSurface--text medium-font" v-text="product.title"></span></div>
       </div>
       </v-row>
       <v-row class="d-flex align-center justify-space-between pb-2">
          <!-- <div class="ml-auto pa-2 mr-2 mb-2">
           <v-icon size="25" class="rateIcons--text" v-if='!like' @click="like=!like">mdi-cards-heart</v-icon>
           <v-icon size="25" class="error--text" v-if='like'>mdi-cards-heart</v-icon>
           <v-icon size="25" class="rateIcons--text" v-if="!share" @click="share=!share">mdi-share-variant</v-icon>
           <v-icon size="25" class="info--text" v-if="share">mdi-share-variant</v-icon>
           <v-icon>mdi-bookmark-outline</v-icon>
           <v-icon class="primaryText--text">mdi-bookmark</v-icon>
          </div> -->
          <div dir="ltr" class="mr-auto pa-2 ml-2">
            <rating :rating = 'product.rate' :config = 'config'/>
          </div>
       </v-row>
    </v-card>
</template>

<script>
import rating from './rate'
export default {
  props: ['product'],
  components: {
    rating
  },
   data:() => ({
    like: false,
    share: false,
    config: {
      width: 20,
      increment:0.5,
      rounded:true,
      showRating:false,
      readonly:true
    }
  })
}
</script>