<template>
    <div>
       <v-btn
        type="button"
        class="rounded-btn quantity-btn py-5 secondaryActions onSecondaryActions--text">
         <v-icon v-if="slider.onhand != 0" size="19" class="onSecondaryActions--text">mdi-plus</v-icon><span v-if="slider.onhand != 0" class="basketBtnFilltext--text">سبد خرید</span>
         <span v-if="slider.onhand == 0" class="onSecondaryActions--text">ناموجود</span>
        </v-btn>
    </div>
</template>
<script>
export default {
    props: ['slider']
}
</script>