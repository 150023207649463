import axios from "axios"
import Store from '@/store/index'
import{ changeTypePrice, changeTypecount, calOffer } from '@/models/changeType'
import router from "@/router"
import store from "@/store/index"
const token = localStorage.getItem('access_token')

export function add_comment(comment: {}) {
    store.dispatch('load_page', true)
    axios.post('/api/Store/Products/comment',comment,{
     headers: {
      'Authorization': `Bearer ${token}`
     }
    }).then(Response => {
        store.dispatch('load_page', false)
        if (Response.data.Result.Result == 1) {
            store.dispatch('alertSuccess', {show: true, type: 'comment'})
            setTimeout(() => {
                store.dispatch('alertSuccess', {show: false, type: 'network'})
                router.back()
            }, 1500);
        } else {
            store.dispatch('alertError', {show: true, type: 'network'})
            setTimeout(() => {
                store.dispatch('alertError', {show: false, type: 'network'})
            }, 2500)
        }
    })
}

export function comments_product(productID: string) {
    axios.post('/api/Store/Products/comments',{
      product_id: productID
    }).then(Response => {
        store.dispatch('comments_product', Response.data.Result)
    }) 
}