<template>
  <div>
    <v-card class=" detail-card mt-3" color="background">
      <!-- <v-row class="pt-3">
        <v-col cols="4" class="pl-0">
          <span class="medium-font">فروشنده:</span>
        </v-col>
        <v-col cols="6" class="d-flex align-center pr-0">
          <span class="medium-font">فروشگاه میتلند</span>
          <span class="medium-font info--text" @click="seller()">7 فروشنده</span>
        </v-col>
      </v-row> -->
       <v-row>
         <v-col cols="4" class="pl-0">
            <span class="medium-font onBackground--text">قیمت:</span>
         </v-col>
         <v-col cols="8" class="d-flex align-center pr-0">
            <span class="medium-font error--text text-decoration-line-through ml-2" v-if="product.price != product.priceGross && product.priceGross != 0" v-text="product.priceGrossFormate" ></span>
            <div>
            <span class="medium-font onBackground--text" v-if="product.Is_Bulk != 1" v-text = 'product.priceFormate'></span>
            <span class="medium-font onBackground--text" v-if="product.Is_Bulk == 1" v-text = 'product.price_format_pack'></span>
            </div>
            <!-- <span class="medium-font">از 140,000تومان تا 145,000تومان</span> -->
         </v-col>
       </v-row>
       <v-row v-if="product.group.title">
         <v-col cols="4" class="pl-0">
            <span class="medium-font onBackground--text">دسته بندی:</span>
         </v-col>
         <v-col cols="8" class="pr-0">
            <span class="medium-font onBackground--text" v-text="product.group.title"></span>
         </v-col>
       </v-row>
       <v-row v-if="product.Brand_Title">
         <v-col cols="4" class="pl-0">
            <span class="medium-font onBackground--text">برند:</span>
         </v-col>
         <v-col cols="8" class="pr-0">
            <span class="medium-font onBackground--text" v-text="product.Brand_Title"></span>
         </v-col>
       </v-row>
       <v-row>
         <v-col cols="4" class="pl-0">
            <span class="medium-font onBackground--text">نوع بسته بندی:</span>
         </v-col>
         <v-col cols="8" class="pr-0">
            <span class="medium-font onBackground--text" v-text = 'product.unit_title'></span>
         </v-col>
       </v-row>
       <!-- <v-row>
         <v-col cols="4">
            <span class="font">طعم</span>
         </v-col>
         <v-col cols="8">
            <span class="font">ساده</span>
         </v-col>
       </v-row> -->
       <v-divider class="my-4"></v-divider>
       <div v-if="product.description">
        <span class="base-font onBackground--text">توضیحات</span>
        <div>
        <p class="medium-font onBackground--text" v-text="product.description"></p>
        </div>
       </div>
       <div>
        <div v-if="addToBasket">
           <select-btn class="fixed" v-if="product.Is_Bulk == 0 || !product.Is_Bulk" :slider = 'product' @show= 'show' :orders = 'in_basket' />
           <overflow-btn class="fixed" v-if="product.Is_Bulk == 1" :slider = 'product' @show= 'show' :orders = 'in_basket' color="secondaryActions" block = 'true'/>
         </div>
       </div>
       <div @click="openOrder">
        <btn :product = 'product' v-if="!addToBasket" class="detail-card fixed"/>
       </div>
       <v-divider class="my-4"></v-divider>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.fixed{
   position: sticky;
   bottom: 10px;
   right: 0;
   left: 0;
   z-index: 1;
}
</style>
<script>
import btn from '../buttons/basketBtnFill'
import SelectBtn from '../../../home/components/buttons/selectBtn.vue'
import OverflowBtn from '../../../home/components/buttons/overflowBtn.vue'
import { mapState } from 'vuex'
export default {
   props: ['product'],
   components: {
      btn,
      OverflowBtn,
      SelectBtn
   },
   data: () => ({
      addToBasket: false,
      orders: {
        order: {},
        orderd: false
      }
   }),
   computed: {
    ...mapState({
        basket: state => state.basket.basket,
    }),
      in_basket () {
        let product = {}
        let orderd = false
        for (const pro of this.basket) {
          if (pro.id == this.$props.product.id) {
            product = pro
            orderd = true
            console.log(product)
          }
        }
        return {order: product, orderd: orderd}
      }
  },
  watch: {
    in_basket: {
      handler: function () {
        if (this.in_basket.orderd == true) {
          this.addToBasket = true
        }
      }
    }
  },
  methods: {
     show (show) {
        this.addToBasket = show
      },
      openOrder () {
        if (this.$props.product.onhand != 0) {
          this.addToBasket = true
        }
      },
    seller () {
      this.$router.push('/sellers')
    }
  }
}
</script>
