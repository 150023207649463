<template>
<div>
     <v-card v-for="comment in comments" :key="comment.Comment_ID_PK" class="pa-3 card flat-card" color="surface">
          <v-row class="mt-3 pr-3">
            <v-avatar tile size="50" color="primary" class="mb-2">
              <v-img :src="comment.Photo"></v-img>
            </v-avatar>
            <div class="d-flex flex-column align-start justify-start  mr-4">
            <span class="medium-font onSurface--text" v-text="comment.Name"></span>
            <span class="darkDisable--text smallFont mt-2" v-text="comment.Insert_Time"></span>
            </div>
            <v-spacer></v-spacer>
            <rating dir="ltr" :rating = 'comment.Rate' :config = 'config' class="ml-2"/>
          </v-row>
          <v-divider class="my-4 dividers"></v-divider>
          <v-row class="mb-2 pr-3">
            <p class="onSurface--text medium-font mt-2" v-text="comment.Comment_Text"></p>
          </v-row>
        <v-divider class="my-1"></v-divider>
        </v-card>
</div>
</template>
<script>
import { mapState } from 'vuex'
import rating from '../cards/rate'
export default {
  components:{
    rating
  },
  data: () => ({
    config: {
      width: 17,
      rounded:true,
      showRating:false,
      readonly:true
    }
  }),
  computed: {
    ...mapState({
      comments: state => state.productDetail.comments_product
    })
  }
}
</script>